<template>
    <div class="predictBox">
        <el-form-item label="选择赛事">
            <el-select style="width: 100%" v-model="match_id" filterable placeholder="请选择">
                <el-option
                v-for="item in data"
                :key="item.match_id"
                :label="item.match_text"
                :value="item.match_id">
                </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="">
            <div class="predict">
                <div class="tips">总进球</div>
                <div v-if="score_info.length > 0" class="resultBox">
                    <p
                        v-for="(item, index) in odds_info" :key="index"
                        @click="itemClick(item)"
                        :class="{'active':(predict_result == item.type || second_predict_result == item.type)}"
                    >
                        <span>{{item.type}}球</span>
                        <em>({{item.value}})</em>
                    </p>
                </div>
                <div class="empty" v-else>请先选择赛事~~</div>
            </div>
        </el-form-item>
        <el-form-item label="">
            <div class="scorePredict">
                <div class="tips">比分</div>
                <div v-if="odds_info.length > 0" class="scoreResultBox">
                    <div class="score win">
                        <div class="scoreTips">胜</div>
                        <div class="scoreBox">
                            <div
                                v-for="(item, index) in score_win" :key="index"
                                class="item" :class="{'itemLong':item.type==='1h','selected':item.type == first_predict_score_result || item.type == second_predict_score_result,
                                'disabled':(item.total!=predict_result && item.total != second_predict_result) && !((item.type == '1h' || item.type == '1a' || item.type == '1d') && (predict_result == 6 || second_predict_result == 6))}"
                                @click="scoreClick(item)"
                            >
                                <p>{{item.type === '1h'?'胜其他':item.type}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="score draw">
                        <div class="scoreTips">平</div>
                        <div class="scoreBox">
                            <div
                                v-for="(item, index) in score_draw" :key="index"
                                class="item" :class="{'selected':item.type == first_predict_score_result || item.type == second_predict_score_result,'disabled':(item.total+'')!=predict_result && (item.total+'') != second_predict_result}"
                                @click="scoreClick(item)"
                            >
                                <p>{{item.type === '1d'?'平其他':item.type}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="score loss">
                        <div class="scoreTips">负</div>
                        <div class="scoreBox">
                            <div
                                v-for="(item, index) in score_loss" :key="index"
                                class="item" :class="{'itemLong':item.type==='1a','selected':item.type == first_predict_score_result || item.type == second_predict_score_result,
                                'disabled':(item.total!=predict_result && item.total != second_predict_result) && !((item.type == '1h' || item.type == '1a' || item.type == '1d') && (predict_result == 6 || second_predict_result == 6))}"
                                @click="scoreClick(item)"
                            >
                                <p>{{item.type==='1a'?'负其他':item.type}}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="empty" v-else>请先选择赛事~~</div>
            </div>
        </el-form-item>
    </div>
</template>

<script>
import api from "../../service/api"
export default {
    name: 'predictItem',
    props: ['data'],
    data () {
        return {
            match_id: '',
            odds_info: [],
            score_info: [],
            score_win: [],
            score_draw: [],
            score_loss: [],
            predict_result: '',
            first_odds: '',
            second_predict_result: '',
            second_odds: '',
            first_predict_score_result: '',
            second_predict_score_result: ''
        }
    },
    watch: {
        match_id () {
            this.reset();
            this.odds_info = [];
            this.score_info = [];
            this.second_predict_result = '';
            for (let i = 0; i < this.data.length; i++) {
                if (this.data[i].match_id === this.match_id) {
                    this.odds_info = this.data[i].odds_info.fields || []
                }
            };
            // 获取比分赔率
            this.initScoreInfo(this.match_id)
        },
        score_info () {
            const data = this.score_info;
            const scoreWin = [];
            const scoreDraw = [];
            const scoreLoss = [];
            let winOther = null;
            let drawOther = null;
            let lossOther = null;
            for (let i = 0; i < data.length; i++) {
                if (data[i].type === '1a') {
                    lossOther = data[i]
                } else if (data[i].type === '1h') {
                    winOther = data[i];
                } else if (data[i].type === '1d') {
                    drawOther = data[i]
                } else {
                    const score1 = parseInt(data[i].type.split('-')[0]);
                    const score2 = parseInt(data[i].type.split('-')[1]);
                    if (score1 > score2) {
                        scoreWin.push(data[i])
                    } else if (score1 === score2) {
                        scoreDraw.push(data[i])
                    } else {
                        scoreLoss.push(data[i])
                    }
                    scoreWin.sort((a, b) => {
                        return a.type - b.type
                    })
                    scoreDraw.sort((a, b) => {
                        return b.type - a.type
                    })
                    scoreLoss.sort((a, b) => {
                        return b.type - a.type
                    })
                }
            }
            scoreWin.push(winOther);
            scoreDraw.push(drawOther);
            scoreLoss.push(lossOther);
            this.score_win = scoreWin;
            this.score_draw = scoreDraw;
            this.score_loss = scoreLoss;
        },
        predict_result () {
            this.dealScoreSelected()
        },
        second_predict_result () {
            this.dealScoreSelected()
        }
    },
    methods: {
        dealScoreSelected () {
            if (this.first_predict_score_result) {
                if (!((this.first_predict_score_result === '1h' || this.first_predict_score_result === '1a' || this.first_predict_score_result === '1d') && (this.predict_result === '6' || this.second_predict_result === '7+'))) {
                    const score1 = parseInt(this.first_predict_score_result.split('-')[0]);
                    const score2 = parseInt(this.first_predict_score_result.split('-')[1]);
                    if ((score1 + score2) !== parseInt(this.predict_result) && (score1 + score2) !== parseInt(this.second_predict_result)) {
                        this.first_predict_score_result = ""
                    }
                }
            }
            if (this.second_predict_score_result) {
                if (!((this.second_predict_score_result === '1a' || this.second_predict_score_result === '1d' || this.second_predict_score_result === '1h') && (this.predict_result === '6' || this.second_predict_result === '7+'))) {
                    const score1 = parseInt(this.second_predict_score_result.split('-')[0]);
                    const score2 = parseInt(this.second_predict_score_result.split('-')[1]);
                    if ((score1 + score2) !== parseInt(this.predict_result) && (score1 + score2) !== parseInt(this.second_predict_result)) {
                        this.second_predict_score_result = ""
                    }
                }
            }
        },
        scoreClick (item) {
            if (!this.predict_result && !this.second_predict_result) {
                this.$message.error('请先选择总进球');
                return
            }
            if (item.total === '7+') {
                if (!(this.predict_result === '6' || this.second_predict_result === '6' || this.predict_result === '7+' || this.second_predict_result === '7+')) {
                    this.$message.error('请选择与总进球方向一致的选项');
                    return
                }
            } else if ((item.total + '') !== this.predict_result && (item.total + '') !== this.second_predict_result) {
                this.$message.error('请选择与总进球方向一致的选项');
                return
            }
            if (this.first_predict_score_result && this.second_predict_score_result) {
                if (this.first_predict_score_result === item.type) {
                    this.first_predict_score_result = ""
                } else if (this.second_predict_score_result === item.type) {
                    this.second_predict_score_result = ""
                } else {
                    this.second_predict_score_result = item.type
                }
            } else {
                if (this.first_predict_score_result === item.type) {
                    this.first_predict_score_result = ""
                } else if (this.second_predict_score_result === item.type) {
                    this.second_predict_score_result = ""
                } else {
                    if (!this.first_predict_score_result) {
                        this.first_predict_score_result = item.type
                    } else {
                        this.second_predict_score_result = item.type
                    }
                }
            }
            this.$emit('setRes', {
                match_id: this.match_id,
                predict_result: this.predict_result,
                first_odds: this.first_odds,
                second_predict_result: this.second_predict_result,
                second_odds: this.second_odds,
                first_predict_score_result: this.first_predict_score_result,
                second_predict_score_result: this.second_predict_score_result
            })
        },
        async initScoreInfo (matchId) {
            const res = await api.initScoreInfo({
                match_id: matchId
            })
            if (res.code === 0) {
                this.score_info = res.data.score_odds
            }
        },
        reset () {
            this.predict_result = '';
            this.first_odds = '';
            this.second_predict_result = '';
            this.second_odds = '';
        },
        // TODO reset
        itemClick (obj) {
            const res = obj.type;
            const odds = obj.value;
            if (!this.predict_result) {
                this.predict_result = res;
                this.first_odds = odds;
            } else if (this.predict_result === res) {
                if (this.second_predict_result) {
                    this.predict_result = this.second_predict_result;
                    this.first_odds = this.second_odds;
                    this.second_predict_result = "";
                    this.second_odds = "";
                } else {
                    this.predict_result = "";
                    this.first_odds = ""
                }
            } else {
                if (!this.second_predict_result) {
                    this.second_predict_result = res;
                    this.second_odds = odds;
                } else {
                    if (this.second_predict_result === res) {
                        this.second_predict_result = ""
                        this.second_odds = ""
                    } else {
                        this.predict_result = res;
                        this.first_odds = odds
                    }
                }
            }
            if (this.predict_result === "" && this.second_predict_result === "") {
                this.reset()
            }
        }
    }
}
</script>

<style lang="less" scoped>
.predictBox{
    .predict{
        display: flex;
        justify-content: space-between;
        text-align: center;
        .tips{
            width: 30px;
            background-color: #eee;
            padding: 0 5px;
            height: 80px;
            line-height: 18px;
            display: flex;
            align-items: center;
        }
        .resultBox{
            width: calc(100% - 30px);
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            background-color: #f5f5f5;
            border-top: 1px solid #eee;
            p{
                width: 25%;
                height: 40px;
                line-height: 40px;
                border-bottom: 1px solid #eee;
                border-right: 1px solid #eee;
                cursor: pointer;
            }
            p.active{
                background-color: red;
                color: #fff;
            }
        }
        .empty{
            width: calc(100% - 30px);
            text-align: center;
            line-height: 80px;
            background-color: #f2f2f2;
        }
    }
    .scorePredict{
        display: flex;
        justify-content: space-between;
        .tips{
            width: 30px;
            background-color: #eee;
            padding: 0 5px;
            line-height: 18px;
            display: flex;
            align-items: center;
        }
        .scoreResultBox{
            width: calc(100% - 30px);
            background-color: #f5f5f5;
            border-top: 1px solid #eee;
            .score{
                display: flex;
                justify-content: space-between;
                .scoreTips{
                    width: 30px;
                    padding: 0 5px;
                    display: flex;
                    align-items: center;
                    color: #fff;
                }
                .scoreBox{
                    width: calc(100% - 30px);
                    background-color: #f5f5f5;
                    border-top: 1px solid #eee;
                    display: flex;
                    justify-content: space-between;
                    flex-wrap: wrap;
                    .item{
                        width: 20%;
                        text-align: center;
                        border-bottom: 1px solid #eee;
                        border-right: 1px solid #eee;
                        cursor: pointer;
                        height: 40px;
                        p{
                            font-size: 14px;
                            color: #333;
                            line-height: 40px;
                        }
                    }
                    .itemLong{
                        width: 60%;
                    }
                    .selected{
                        background-color: red;
                        p, span{
                            color: #fff;
                        }
                    }
                    .disabled{
                        background-color: #999;
                    }
                }
            }
            .win .scoreTips{
                background-color:rgb(231, 25, 25);
            }
            .draw .scoreTips{
                background-color: #333;
            }
            .loss .scoreTips{
                background-color: rgb(63, 87, 224);
            }
        }
        .empty{
            width: calc(100% - 30px);
            text-align: center;
            line-height: 80px;
            background-color: #f2f2f2;
        }
    }
}
</style>
